
.spinSize {
    font-size:46px;
  }

  .customDropdown {
    width: 240px; /* Set the desired width */
  }
  
  .customInput {
    width: 100%; /* Set the desired width */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }